@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap);
.App {
  text-align: center;
  width: 100%;
  max-height: 100vh;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.App::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.App {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media only screen and (min-width:551px) {
  .App_class {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
}


/* Make direction as column to make the menu bar to appear at the bottom. */
@media only screen and (max-width:550px) {
  .App {
    max-height: 92vh;
  }

  .App_class {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

*:-webkit-full-screen {
  background-color: #FFF !important;
  ;
}

*:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: #FFF !important;
  ;
}

::-webkit-backdrop {
  z-index: 0;
  background-color: white !important;
}

::backdrop {
  z-index: 0;
  background-color: white !important;
}
.userFormCard {
    width: 50%;
}

@media only screen and (max-width:750px) {
    .userFormCard {
        width: 95%;
    }
}
.category-edit-button-style {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

@media only screen and (max-width:750px) {
    .category-edit-button-style {
        display: grid;
    }
}
.category-button-ghost-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.category-button-ghost-style {
    margin-left: 10px;
}

@media only screen and (max-width:750px) {
    .category-button-ghost-wrapper {
        display: block !important;
    }

    .category-button-ghost-style {
        margin-left: 0px !important;
        margin-bottom: 10px !important;
    }
}
.LottieAnim svg{
    max-width:300px;
    max-height:300px;
}
.Dashboard {
    margin: 20px;
}

.ant-tabs {
    padding-bottom: 10px;
}

.Dashboard::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
    /* or add it to the track */
}

/* Add a thumb */
.Dashboard::-webkit-scrollbar-thumb {
    background: #000;
}

.ant-tabs-content-holder {
    text-align: -webkit-center;
}

.ant-list-items {
    max-width: 980px;
    text-align: left;
}

.backToTop {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4;
    background-color: '#1088e9';
    color: '#fff';
    text-align: 'center';
    font-size: 14;
}

@media only screen and (min-width:551px) {
    .ListDetails>p {
        width: 50%;
    }

    .right {
        float: right;
    }

    .Dashboard .ant-tabs-content-holder {
        overflow-y: scroll;
        max-height: 90vh;
    }

    .Dashboard .ant-tabs {
        height: 95vh;
    }

}

@media only screen and (max-width:551px) {
    .ant-collapse-content>.ant-collapse-content-box {
        padding: 5px;
    }

    .Dashboard {
        height: 87vh;
    }

    .ant-list-item-action {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .ant-list-item-action>li {
        padding: 2px 0px
    }

    .Dashboard .ant-tabs-content-holder {
        overflow-y: scroll;
        max-height: 88vh;
    }

    .Dashboard .ant-tabs {
        max-height: 85vh;
    }

    .LottieAnim{
        margin-top:35%;
    }
}

.ant-card {
    margin: 10px;
    border-radius: 1px solid black;
    border-radius: 10px;
}

.success {
    background-color: rgb(225, 255, 225);
}

.pending {
    background-color: rgb(255, 228, 220);
}

.items {

    display: -webkit-flex;

    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.ant-collapse-content-box {
    font-size: 12px;
}

.ListItems {
    max-width: 980px;
    text-align: left;
}

.ant-divider-vertical {
    border-left: 1px solid rgb(0 0 0 / 25%);
}

.site-custom-tab-bar {
    z-index: 1;
    background: #fff;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Dashboard .ant-tabs-content-holder::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Dashboard .ant-tabs-content-holder {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
.editRate {
    margin: 20px;
    text-align: -webkit-center;
}

@media only screen and (min-width:551px) {
    .EditCollapse {
        max-width: 880px;
    }

    .editable-cell-value-wrap {
        padding: 2px !important;
    }
}

@media only screen and (max-width:551px) {
    .editRate {
        height: 87vh;
    }
}

.editRate .editable-cell {
    position: relative;
}

.editRate .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
    background-color: rgb(189, 230, 255);

}

.S-editable-cell-value-wrap {
    background-color: #6d98da;
}

.B-editable-cell-value-wrap {
    background-color: rgb(221, 143, 69);
}

.editRate .ant-row .ant-form-item {
    width: 30%;
}

.editRate .editable-row:hover .editable-cell-value-wrap:hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: rgb(189, 230, 255);
}

.editRate .ant-table-thead>tr>th {
    background: #4e8ae4;
    font-size: 1.1rem;
    color: whitesmoke;
    text-align: center;
}

.editRate .ant-table-thead>tr>th.col-buyer {
    background-color: #df7d1b !important;
    font-size: 13px;

}

.editRate .ant-table-thead>tr>th.col-seller {
    background-color: rgb(122, 172, 248);
    font-size: 13px;

}

/* .ant-table-tbody > tr.ant-table-row .highlight:hover{
    background-color:rgb(189, 230, 255);
} */


/* 
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 0px !important; 
} */
.register-panel {
    position: absolute;
    top: 49%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 450px;
    height: 550px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 30px 5%;
  }

  .register-panel .login-form {
    max-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .login-form-forgot {
    float: left;
    color: #8f8f8f;
    display: -webkit-flex;
    display: flex;
  }
  .login-form-forgot > div:nth-child(2) {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }

  .ant-btn-primary {
    background: #ff8d1a;
    border-color: #ff8d1a;
  }

  /* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff8d1a;
    border-color: #ff8d1a;
  } */

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff8d1a;
  }

  .ant-form-item-has-error .ant-input-affix-wrapper {
    border-color: #ff8d1a;
  }

  .ant-form-item-control-input-content {
    text-align: left;
  }

  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
      width: 35vw;
  }

  @media (max-width: 600px) {
    .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
        width: 85vw;
    }

    .register-panel {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: rgb(255, 255, 255);
        border-radius: 0px;
        box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    }
  }
.login-form {
  max-width: 300px;
  position: absolute;
  top: 52%;
  left: 48%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.login-panel .login-form-forgot {
  /* float: left; */
  color: #454545;
  display: -webkit-flex;
  display: flex;
}

.login-form-forgot>div:nth-child(2) {
  /* float: right; */
}

.login-panel .login-form-forgot .ant-form-item-control-input-content {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.login-panel .login-form-forgot .ant-form-item-control-input-content div:nth-child(2) {
  /* margin-left: 105px; */
  color: #ff8d1a;
}

.login-mpin .ant-form-item {
  margin-bottom: 5px !important;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}

@media (min-width:700px) {

  .login-panel {
    position: absolute;
    top: 49%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 450px;
    height: 600px;
    margin-top: 10px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 30px 5%;
  }
}

.login-panel:focus {
  position: absolute;
  top: 49%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 450px;
  height: 600px;

  border-radius: 15px;
  box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
  padding: 30px 5%;
}

.signup {
  position: absolute;
  top: 49%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid #ff8d1a;

  /* box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px; */
  /* padding: 30px 5%; */
}



.mpinc {
  width: 50px;
}

.login-form-button {
  background: #ff8d1a;
  border-color: #ff8d1a;
  color: white;
}


.login-form-button:focus {
  background: #ff8d1a;
  border-color: #ff8d1a;
  color: white;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6fd4b1;
    border-color: #6fd4b1;
  } */

.ant-form-item-explain.ant-form-item-explain-error {
  color: #3f260d;
}

.ant-form-item-has-error .ant-input-affix-wrapper {
  border-color: #ff8d1a;
}

.ant-form-item-control-input-content {
  text-align: left;
}

.otp-input .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}

@media (max-width: 600px) {
  .otp-input .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 85vw;
  }

  .login-panel {
    position: absolute;
    top: 50%;
    left: 48%;

  }

  .signup {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border: 1px solid #ff8d1a;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
  }
}
@media only screen and (min-width:551px) {
    .ant-menu-dark.ant-menu-horizontal {
        display: none;
    }

    .ant-menu-dark.ant-menu-inline,
    .ant-menu-dark.ant-menu-vertical,
    .ant-menu-dark.ant-menu-vertical-left,
    .ant-menu-dark.ant-menu-vertical-right {
        border-right: 0;
        height: 100vh;
    }

    .ant-menu-inline-collapsed:not(.ant-layout-sider-children > ul) {
        min-width: 50px !important;
        max-width: 50px !important;
    }
}

@media only screen and (max-width:550px) {
    .ant-menu-inline-collapsed:not(.ant-layout-sider-children > ul) {
        display: none
    }

    .ant-menu-dark.ant-menu-horizontal {
        height: 55px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-content: center;
                align-content: center;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
    }

    .ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
    .ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
        height: 55px;
    }

    .ant-menu-item .ant-menu-item-icon {
        margin-right: 0px;
    }

}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
    font-size: 1.4rem;
    font-weight: bold;
    vertical-align: -webkit-baseline-middle;
}

.ant-menu-dark>.ant-menu-item:hover {
    background-color: #FF9600 !important;
}



.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #FF9600 !important;
}

.ant-menu-item-selected {
    background-color: #FF9600 !important;
}

.ant-menu.ant-menu-dark {
    background: #4e8ae4 !important;
}

.Mobile_Menu {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.EditProfile{
    height:92vh;
}

.EditLayout{
    margin:4%;
    padding:2%;
}

.ant-form-item-label {
    text-align: right;
}

.submit{
    background-color: #265090;
    color: white;
}

.submit:focus{
    background-color: #265090;
    color: white;
}

@media only screen and (min-width:550px){
    .userInfo{
    -webkit-justify-content: center;
            justify-content: center;
    }
    .userInfo__name{
        margin:15px;
    }
    /* .userInfo__profileImage{
        margin:15px;
    } */
}

@media only screen and (max-width:551px){
    .userInfo{
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .profile{
        height:92vh;
    }
}

.userInfo{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 10px 20px;
    font-size: 1.3rem;
}

.userInfo__name .Name{
    font-weight: bold;
}

.heading{
    text-align: left;
    background-color: #4e8ae4;
    font-size:15px;
    
}
.heading > h2{
    margin-left:1%;
    color:whitesmoke;
    padding-left: 5px;
}
.heading > h3{
    color:whitesmoke;
}
.userInfo__name .ID{
    font-size:small;
}

.ProfileDetails .heading{
    margin: 3%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: flex-end;
            align-content: flex-end;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0px 5px;
}

.ant-btn{
    border-radius: 10px;
}

.Details{
    margin:3%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-content: center;
            align-content: center;
}


.Logout{
    margin:1%;
    float:left;
}
/* .userInfo__profileImage img{
    max-width:100px;
    max-height:100px;
    border-radius: 50%;
} */
.register-panel {
    position: absolute;
    top: 49%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 450px;
    height: 550px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 30px 5%;
  }

  .register-panel .login-form {
    max-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .login-form-forgot {
    float: left;
    color: #8f8f8f;
    display: -webkit-flex;
    display: flex;
  }
  .login-form-forgot > div:nth-child(2) {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }


  /* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff8d1a;
    border-color: #ff8d1a;
  } */

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff8d1a;
  }

  .ant-form-item-has-error .ant-input-affix-wrapper {
    border-color: #ff8d1a;
  }

  .ant-form-item-control-input-content {
    text-align: left;
  }

  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
      width: 35vw;
  }

  @media (max-width: 600px) {
    .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
        width: 85vw;
    }

    .register-panel {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: rgb(255, 255, 255);
        border-radius: 0px;
        box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    }
  }
@media only screen and (max-width:575px) {
    .UserRegistration {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .userFormCard {
        margin: 10px;
        padding: 10px;
    }

    .userFormCard>.ant-card-body {
        padding: 0px;
    }
}

@media only screen and (min-width:575px) {
    .RegistrationForm .ant-form-item-control-input {
        width: 60%;
    }

    .UserRegistration {
        height: 92vh;
    }
}

.UserRegistration {
    margin: 20px;
}

.UserRegistration .ant-input:-ms-input-placeholder {
    font-size: 0.56rem;
}

.UserRegistration .ant-input:placeholder-shown {
    font-size: 0.56rem;
}
h4 {
    margin-bottom: 16px;
}

.requirement-container {
    margin: 20px;
    overflow-x: visible;
    height: 85vh;
    text-align: -webkit-center;
    overflow-y: auto;
    margin-bottom: 50px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.requirement-container::-webkit-scrollbar {
    display: none;
}

.confirm-btn {
    float: right;
}

.FormItem {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

@media only screen and (min-width:551px) {
    .RequirmentCollapse {
        max-width: 880px;
    }

    .requirement-container {
        height: 85vh;
    }
}

@media only screen and(max-width:551px) {
    .requirement-container {
        height: 85vh;
    }  
}

@media screen and (max-width: 700px) {
    .ant-table-thead > tr >th {
        /* color: #7d7272; */
        background: #3071b9 !important;
        font-size: 12px;
        padding: 16px;
    }

    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.3s !important; 
        font-size: 12px;
    }
}
.requirement-container .submit-button .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 100%;
    margin-bottom: 10px;
}

.file-upload-button {
    padding: 10px;
    background-color: white;
    border: 0;
}

@media only screen and (min-width:551px) {
    .file-upload-button {
        max-width: 880px;
    }
}

.submit{
    background-color: #265090;
    color: white;
}

.cancel{
    background-color: #265090;
    color: white;
}

.submit:focus{
    background-color: #265090;
    color: white;
}

.cancel:focus{
    background-color: #265090;
    color: white;
}




.category-container {
  margin: 50px;
  overflow-x: hidden;
  height: 92vh;
}

.ant-table-thead>tr>th {
  /* background: #b32d2b; */
  background: #4e8ae4;
  /* font-size: 1.1rem; */
  color: whitesmoke;
  text-align: center;
  font-weight: bold;

}

.ant-table-filter-column-title {
  font-size: 13px;
}

.ant-table-thead>tr>.ant-table-cell-fix-left-last {
  background-color: #df7d1b !important;
  font-size: 13px;
  font-weight: bold;
}

.ant-table-thead>tr>.ant-table-cell-fix-right-first {
  background-color: rgb(82, 143, 236);
  font-size: 13px;
  font-weight: bold;

}

.ant-table-cell-fix-left-last .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fdbc7a;
  font-size: 13px;
}

.ant-table-cell-fix-right-first .ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(122, 172, 248);
}

.ant-table-filter-trigger-container:hover {
  background: #4e8ae4;
  font-size: 13px;
}

.ant-table-filter-trigger.active {
  color: #b32d2b;
}

.ant-table-filter-trigger {
  color: #333333;

}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
}


.ant-table.ant-table-middle .ant-table-tbody>tr>td {
  font-weight: 600;
}


.btn-continue {
  border-radius: 4px;
  border: none;
  color: #333333;
  text-align: center;
  font-size: 14px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  background-color: #265090;
  color: white;

}

.btn-continue span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  margin-bottom: 0%;
  color: #ffffff;
  background-color: #265090;


}

.btn-continue span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
  background-color: #265090;

}

.btn-continue:hover span {
  padding-right: 25px;
  color: #ffffff;
  background-color: #265090;

}

.btn-continue:hover {
  padding-right: 25px;
  background-color: #265090;

}

.btn-continue:hover span:after {
  opacity: 1;
  right: 0;
  background-color: #265090;

}

.btn-continue:focus {
  background-color: #265090;

}


@media (max-width: 571px) {
  .category-container {
    margin: 10px;
  }
}

@media (min-width: 571px) {
  .category-container {
    margin: 10px;
    height: 98vh;
  }
}

.ant-table-filter-trigger.active {
  background-color: aliceblue;
}
.buyerSellerSwitch_Container{
    position: relative;
    left: 10px;
}

.buyerSellerSwitch{
    margin-left: 5px;
    margin-right: 5px;
}
/* .ant-table-column-sorters {
  background: #4e8ae4 !important;
    color: #f5f5f5;
    text-align: center;
    font-weight: 700;
} */


.buyerDataTable .ant-table-thead .ant-table-cell {
    background-color: #FF8000 !important;
}
.buyer-seller-columns{
    display: -webkit-flex;
    display: flex;
}

.sellerTable{
    max-width: 40% !important;
}

.buyerTable  .ant-table-thead .ant-table-cell {
    background-color: #FF8000 !important;
}

.buyerTable  .ant-table-thead .ant-table-cell:first-child  {
    background-color: #2B46A6 !important;
    border: none !important;
}

.buyerTable  .ant-table-thead .ant-table-cell:nth-child(2) {
    background-color: #2B46A6 !important;
}

.buyerTable  .ant-table-thead .ant-table-cell:nth-child(3) {
    background-color: #2B46A6 !important;
}

.sellerTable .ant-table-thead .ant-table-cell{
    background-color: #007AFF !important;
}


/* .buyerTable .ant-table-tbody > tr.ant-table-row-selected > td{
    background-color: #144375 !important;
} */

/* .buyerTable .ant-table-tbody>tr.ant-table-row-selected>td {
    border-bottom: 4px solid #762323;
    text-align: center;
} */

/* .buyerTable .ant-radio-input {
    background-color: red !important;
    border-color: red !important;
  } */

/* @media only screen and (max-width:575px) {

    .send-notification {
        width: 99% !important;
    }

    .send-notification>.ant-card-body {
        padding: 0px;
    }
}

@media only screen and (min-width:575px) {

    .send-notification {
        width: 70% !important;
    }
}

.send-notification {
    display: flex;
}

.notificationFormCard{
    width: 80% !important;
} */

.send-notification{
    /* margin: 50px; */
    margin-top: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 90% !important;
}
body {
    margin-top: 0px;
    margin-left: 0px;
}

.terms_page::-webkit-scrollbar {
    display: none;
}

.terms_page .ant-form-item-control-input-content {
    text-align: center;
}

.terms_page .ant-form-item-control-input {
    width: 100%;
}

@media only screen and (max-width:575px) {
    .terms_page {
        height: 100%;
        width: 100%;
        font: 10px 'Nirmala UI Semilight' !important;
        max-width: 100%;
        overflow: auto;
        -webkit-align-items: center;
                align-items: center;
        -webkit-align-content: center;
                align-content: center;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        margin: auto auto;
        margin-bottom: 50px;
    }

    #page_1 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_1 #p1dimg1 {
        width: 100% !important;
    }

    #page_1 #p1dimg1 #p1img1 {
        width: 90% !important;
        margin: auto auto;
    }

    #page_1 #id1_1 {
        width: 100% !important;
        margin: 0px 0px 0px 45% !important;
    }

    #page_2 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_3 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_4 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_5 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_6 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_7 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_7 #p7dimg1 {
        width: 100% !important;
        white-space: break-spaces !important;
    }

    #page_7 #p7dimg1 #p7img1 {
        width: 90% !important;
        margin: auto auto;
    }

    .p49 {
        padding-left: 25% !important;

    }

    #page_8 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_9 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_10 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_11 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    .ant-form {
        margin: 10px 10px 10px 10px;
    }

    .ant-col-offset-12 {
        margin-left: 40%;
    }
}

@media only screen and (min-width:575px) {
    .terms_page {
        height: 100%;
        width: 100%;
        font: 12px 'Nirmala UI Semilight' !important;
        max-width: 100%;
        overflow: auto;
        -webkit-align-items: center;
                align-items: center;
        -webkit-align-content: center;
                align-content: center;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        margin: auto auto;
    }

    #page_1 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_1 #p1dimg1 {
        width: 100% !important;
    }


    #page_1 #p1dimg1 #p1img1 {
        width: 80% !important;
        margin: auto auto;
    }

    #page_1 #id1_1 {
        width: 100% !important;
        margin: 0px 0px 0px 45% !important;
    }

    #page_1 #id1_2 {
        width: 100% !important;
    }

    #page_2 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_3 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_4 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_5 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_6 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_7 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_7 #p7dimg1 {
        width: 100% !important;
    }

    #page_7 #p7dimg1 #p7img1 {
        width: 90% !important;
        margin: auto auto;
    }

    .p49 {
        padding-left: 45% !important;
    }

    #page_8 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_9 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_10 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    #page_11 {
        margin: 10px 10px 10px 10px !important;
        width: 90% !important;
        white-space: break-spaces !important;

    }

    .ant-form {
        margin: 10px 10px 10px 10px;
    }

    .ant-col-offset-12 {
        margin-left: 40%;
    }
}

#page_1 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 83px 73px;
    padding: 0px;
    border: none;
    width: 721px;
}

#page_1 #id1_1 {
    border: none;
    margin: 0px 0px 0px 265px;
    padding: 0px;
    border: none;
    width: 456px;
    overflow: hidden;
}

#page_1 #id1_2 {
    border: none;
    margin: 32px 0px 0px 2px;
    padding: 0px;
    border: none;
    width: 719px;
    overflow: hidden;
}

#page_1 #p1dimg1 {
    position: absolute;
    top: 1px;
    left: 0px;
    z-index: -1;
    width: 647px;
    height: 25px;
}

#page_1 #p1dimg1 #p1img1 {
    width: 647px;
    height: 25px;
}




#page_2 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 114px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_3 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 100px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_4 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 94px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_5 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 119px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_6 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 262px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_7 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 94px 73px;
    padding: 0px;
    border: none;
    width: 721px;
}

#page_7 #p7dimg1 {
    position: absolute;
    top: 1px;
    left: 0px;
    z-index: -1;
    width: 647px;
    height: 25px;
}

#page_7 #p7dimg1 #p7img1 {
    width: 647px;
    height: 25px;
}




#page_8 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 99px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_9 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 107px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_10 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 93px 75px;
    padding: 0px;
    border: none;
    width: 719px;
}





#page_11 {
    position: relative;
    overflow: hidden;
    margin: 75px 0px 50px 75px;
    padding: 0px;
    border: none;
    width: 649px;
}





.ft0 {
    /*font: bold 19px 'Nirmala UI Semilight'; */
    line-height: 25px;
}

.ft1 {
    /* font: bold 11px 'Nirmala UI Semilight';*/
    line-height: 13px;
}

.ft2 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 38px;
    line-height: 17px;
}

.ft3 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    line-height: 17px;
}

.ft4 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 31px;
    line-height: 18px;
}

.ft5 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    line-height: 18px;
}

.ft6 {
    /* font: 13px 'Nirmala UI Semilight'; */
    line-height: 18px;
}

.ft7 {
    /* font: 13px 'Nirmala UI Semilight'; */
    line-height: 17px;
}

.ft8 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 28px;
    line-height: 18px;
}

.ft9 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 28px;
    line-height: 17px;
}

.ft10 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 33px;
    line-height: 17px;
}

.ft11 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 32px;
    line-height: 17px;
}

.ft12 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 34px;
    line-height: 17px;
}

.ft13 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 29px;
    line-height: 17px;
}

.ft14 {
    /* font: bold 12px 'Nirmala UI Semilight'; */
    line-height: 15px;
}

.ft15 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 36px;
    line-height: 17px;
}

.ft16 {
    /* font: 13px 'Nirmala UI Semilight'; */
    text-decoration: underline;
    line-height: 18px;
}

.ft17 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 26px;
    line-height: 18px;
}

.ft18 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 26px;
    line-height: 17px;
}

.ft19 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 32px;
    line-height: 18px;
}

.ft20 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 33px;
    line-height: 18px;
}

.ft21 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 36px;
    line-height: 17px;
}

.ft22 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 27px;
    line-height: 17px;
}

.ft23 {
    /* font: 13px 'Nirmala UI Semilight'; */
    background-color: #feffff;
    line-height: 18px;
}

.ft24 {
    /* font: 13px 'Nirmala UI Semilight'; */
    background-color: #feffff;
    line-height: 17px;
}

.ft25 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 37px;
    line-height: 17px;
}

.ft26 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 46px;
    line-height: 17px;
}

.ft27 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 44px;
    line-height: 17px;
}

.ft28 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 44px;
    line-height: 18px;
}

.ft29 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 31px;
    line-height: 17px;
}

.ft30 {
    /* font: bold 13px 'Nirmala UI Semilight'; */
    margin-left: 34px;
    line-height: 17px;
}

.ft31 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 25px;
    line-height: 18px;
}

.ft32 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 24px;
    line-height: 18px;
}

.ft33 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 23px;
    line-height: 17px;
}

.ft34 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 46px;
    line-height: 18px;
}

.ft35 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 39px;
    line-height: 17px;
}

.ft36 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 41px;
    line-height: 17px;
}

.ft37 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 39px;
    line-height: 18px;
}

.ft38 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 38px;
    line-height: 18px;
}

.ft39 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 42px;
    line-height: 17px;
}

.ft40 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 43px;
    line-height: 18px;
}

.ft41 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 41px;
    line-height: 18px;
}

.ft42 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 38px;
    line-height: 17px;
}

.ft43 {
    /* font: 13px 'Nirmala UI Semilight'; */
    margin-left: 42px;
    line-height: 18px;
}

.ft44 {
    /* font: bold 14px 'Nirmala UI Semilight'; */
    line-height: 21px;
}

.p0 {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
}

.p1 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p2 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p3 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p4 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 12px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p5 {
    text-align: left;
    padding-left: 47px;
    margin-top: 9px;
    margin-bottom: 0px;
}

.p6 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p7 {
    text-align: justify;
    padding-left: 95px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p8 {
    text-align: left;
    padding-left: 47px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.p9 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p10 {
    text-align: left;
    padding-left: 47px;
    margin-top: 7px;
    margin-bottom: 0px;
}

.p11 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p12 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p13 {
    text-align: left;
    margin-top: 6px;
    margin-bottom: 0px;
}

.p14 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.p15 {
    text-align: left;
    margin-top: 7px;
    margin-bottom: 0px;
}

.p16 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p17 {
    text-align: justify;
    padding-left: 95px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p18 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 75px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p19 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p20 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 14px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p21 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p22 {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0px;
}

.p23 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 28px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p24 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 6px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p25 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 13px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p26 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p27 {
    text-align: left;
    margin-top: 13px;
    margin-bottom: 0px;
}

.p28 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p29 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p30 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 29px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p31 {
    text-align: left;
    margin-top: 4px;
    margin-bottom: 0px;
}

.p32 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p33 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 5px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p34 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 11px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p35 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.p36 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 27px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p37 {
    text-align: left;
    margin-top: 12px;
    margin-bottom: 0px;
}

.p38 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
}

.p39 {
    text-align: left;
    margin-top: 11px;
    margin-bottom: 0px;
}

.p40 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 75px; */
    margin-top: 23px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p41 {
    text-align: left;
    padding-left: 95px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -48px;
}

.p42 {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 0px;
}

.p43 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p44 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p45 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p46 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p47 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p48 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
}

.p49 {
    text-align: left;
    padding-left: 167px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.p50 {
    text-align: left;
    padding-left: 2px;
    margin-top: 32px;
    margin-bottom: 0px;
}

.p51 {
    text-align: justify;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p52 {
    text-align: left;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p53 {
    text-align: justify;
    padding-left: 49px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p54 {
    text-align: left;
    padding-left: 2px;
    margin-top: 11px;
    margin-bottom: 0px;
}

.p55 {
    text-align: left;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p56 {
    text-align: justify;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p57 {
    text-align: left;
    padding-left: 2px;
    margin-top: 7px;
    margin-bottom: 0px;
}

.p58 {
    text-align: left;
    padding-left: 2px;
    margin-top: 9px;
    margin-bottom: 0px;
}

.p59 {
    text-align: left;
    padding-left: 2px;
    margin-top: 8px;
    margin-bottom: 0px;
}

.p60 {
    text-align: left;
    padding-left: 49px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p61 {
    text-align: justify;
    padding-left: 121px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p62 {
    text-align: left;
    padding-left: 49px;
    margin-top: 8px;
    margin-bottom: 0px;
}

.p63 {
    text-align: left;
    padding-left: 121px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p64 {
    text-align: justify;
    padding-left: 121px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p65 {
    text-align: left;
    padding-left: 121px;
    /* padding-right: 75px; */
    margin-top: 11px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p66 {
    text-align: left;
    padding-left: 49px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.p67 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p68 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p69 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p70 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p71 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 25px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p72 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p73 {
    text-align: left;
    margin-top: 9px;
    margin-bottom: 0px;
}

.p74 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p75 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p76 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 9px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p77 {
    text-align: left;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p78 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p79 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 11px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p80 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p81 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p82 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 76px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p83 {
    text-align: justify;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 10px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p84 {
    text-align: left;
    padding-left: 47px;
    margin-top: 8px;
    margin-bottom: 0px;
}

.p85 {
    text-align: left;
    padding-left: 119px;
    /* padding-right: 75px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.p86 {
    text-align: justify;
    padding-left: 47px;
    /* padding-right: 76px; */
    margin-top: 20px;
    margin-bottom: 0px;
    text-indent: -47px;
}

.p87 {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 0px;
}

.p88 {
    text-align: justify;
    padding-left: 119px;
    padding-right: 6px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p89 {
    text-align: justify;
    padding-left: 119px;
    padding-right: 5px;
    margin-top: 7px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p90 {
    text-align: justify;
    padding-left: 119px;
    padding-right: 5px;
    margin-top: 8px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p91 {
    text-align: justify;
    padding-left: 119px;
    padding-right: 5px;
    margin-top: 13px;
    margin-bottom: 0px;
    text-indent: -72px;
}

.p92 {
    text-align: justify;
    margin-top: 7px;
    margin-bottom: 0px;
}
@media only screen and (max-width:575px) {
    .UserRegistration {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .AdminRegistration {
        width: 99% !important;
    }

    .AdminRegistration>.ant-card-body {
        padding: 0px;
    }
}

@media only screen and (min-width:575px) {
    .RegistrationForm .ant-form-item-control-input {
        width: 100%;
    }

    .UserRegistration {
        height: 92vh;
    }

    .AdminRegistration {
        width: 70%;
    }
}

.UserRegistration .ant-input:-ms-input-placeholder {
    font-size: 0.9rem;
}

.UserRegistration .ant-input:placeholder-shown {
    font-size: 0.9rem;
}
.register-panel {
    position: absolute;
    top: 49%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 450px;
    height: 550px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 30px 5%;
  }

  .register-panel .login-form {
    max-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .login-form-forgot {
    float: left;
    color: #8f8f8f;
    display: -webkit-flex;
    display: flex;
  }
  .login-form-forgot > div:nth-child(2) {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }

  .ant-btn-primary {
    background: #ff8d1a;
    border-color: #ff8d1a;
  }

  /* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff8d1a;
    border-color: #ff8d1a;
  } */

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff8d1a;
  }

  .ant-form-item-has-error .ant-input-affix-wrapper {
    border-color: #ff8d1a;
  }

  .ant-form-item-control-input-content {
    text-align: left;
  }

  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
      width: 35vw;
  }

  @media (max-width: 600px) {
    .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
        width: 85vw;
    }

    .register-panel {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: rgb(255, 255, 255);
        border-radius: 0px;
        box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    }
  }
body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

