@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');


.login-form {
  max-width: 300px;
  position: absolute;
  top: 52%;
  left: 48%;
  transform: translate(-50%, -50%);
}

.login-panel .login-form-forgot {
  /* float: left; */
  color: #454545;
  display: flex;
}

.login-form-forgot>div:nth-child(2) {
  /* float: right; */
}

.login-panel .login-form-forgot .ant-form-item-control-input-content {
  display: inline-flex;
  justify-content: space-around;
}

.login-panel .login-form-forgot .ant-form-item-control-input-content div:nth-child(2) {
  /* margin-left: 105px; */
  color: #ff8d1a;
}

.login-mpin .ant-form-item {
  margin-bottom: 5px !important;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}

@media (min-width:700px) {

  .login-panel {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 600px;
    margin-top: 10px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
    padding: 30px 5%;
  }
}

.login-panel:focus {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 600px;

  border-radius: 15px;
  box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
  padding: 30px 5%;
}

.signup {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid #ff8d1a;

  /* box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px; */
  /* padding: 30px 5%; */
}



.mpinc {
  width: 50px;
}

.login-form-button {
  background: #ff8d1a;
  border-color: #ff8d1a;
  color: white;
}


.login-form-button:focus {
  background: #ff8d1a;
  border-color: #ff8d1a;
  color: white;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6fd4b1;
    border-color: #6fd4b1;
  } */

.ant-form-item-explain.ant-form-item-explain-error {
  color: #3f260d;
}

.ant-form-item-has-error .ant-input-affix-wrapper {
  border-color: #ff8d1a;
}

.ant-form-item-control-input-content {
  text-align: left;
}

.otp-input .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}

@media (max-width: 600px) {
  .otp-input .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 85vw;
  }

  .login-panel {
    position: absolute;
    top: 50%;
    left: 48%;

  }

  .signup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border: 1px solid #ff8d1a;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgba(200, 200, 200, 0.63) 2px 2px 7px 2px;
  }
}