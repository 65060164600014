.buyer-seller-columns{
    display: flex;
}

.sellerTable{
    max-width: 40% !important;
}

.buyerTable  .ant-table-thead .ant-table-cell {
    background-color: #FF8000 !important;
}

.buyerTable  .ant-table-thead .ant-table-cell:first-child  {
    background-color: #2B46A6 !important;
    border: none !important;
}

.buyerTable  .ant-table-thead .ant-table-cell:nth-child(2) {
    background-color: #2B46A6 !important;
}

.buyerTable  .ant-table-thead .ant-table-cell:nth-child(3) {
    background-color: #2B46A6 !important;
}

.sellerTable .ant-table-thead .ant-table-cell{
    background-color: #007AFF !important;
}


/* .buyerTable .ant-table-tbody > tr.ant-table-row-selected > td{
    background-color: #144375 !important;
} */

/* .buyerTable .ant-table-tbody>tr.ant-table-row-selected>td {
    border-bottom: 4px solid #762323;
    text-align: center;
} */

/* .buyerTable .ant-radio-input {
    background-color: red !important;
    border-color: red !important;
  } */
