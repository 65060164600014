@media only screen and (min-width:550px){
    .userInfo{
    justify-content: center;
    }
    .userInfo__name{
        margin:15px;
    }
    /* .userInfo__profileImage{
        margin:15px;
    } */
}

@media only screen and (max-width:551px){
    .userInfo{
        justify-content: space-between;
    }
    .profile{
        height:92vh;
    }
}

.userInfo{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 10px 20px;
    font-size: 1.3rem;
}

.userInfo__name .Name{
    font-weight: bold;
}

.heading{
    text-align: left;
    background-color: #4e8ae4;
    font-size:15px;
    
}
.heading > h2{
    margin-left:1%;
    color:whitesmoke;
    padding-left: 5px;
}
.heading > h3{
    color:whitesmoke;
}
.userInfo__name .ID{
    font-size:small;
}

.ProfileDetails .heading{
    margin: 3%;
    display: flex;
    align-content: flex-end;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
}

.ant-btn{
    border-radius: 10px;
}

.Details{
    margin:3%;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
}


.Logout{
    margin:1%;
    float:left;
}
/* .userInfo__profileImage img{
    max-width:100px;
    max-height:100px;
    border-radius: 50%;
} */