.editRate {
    margin: 20px;
    text-align: -webkit-center;
}

@media only screen and (min-width:551px) {
    .EditCollapse {
        max-width: 880px;
    }

    .editable-cell-value-wrap {
        padding: 2px !important;
    }
}

@media only screen and (max-width:551px) {
    .editRate {
        height: 87vh;
    }
}

.editRate .editable-cell {
    position: relative;
}

.editRate .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
    background-color: rgb(189, 230, 255);

}

.S-editable-cell-value-wrap {
    background-color: #6d98da;
}

.B-editable-cell-value-wrap {
    background-color: rgb(221, 143, 69);
}

.editRate .ant-row .ant-form-item {
    width: 30%;
}

.editRate .editable-row:hover .editable-cell-value-wrap:hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: rgb(189, 230, 255);
}

.editRate .ant-table-thead>tr>th {
    background: #4e8ae4;
    font-size: 1.1rem;
    color: whitesmoke;
    text-align: center;
}

.editRate .ant-table-thead>tr>th.col-buyer {
    background-color: #df7d1b !important;
    font-size: 13px;

}

.editRate .ant-table-thead>tr>th.col-seller {
    background-color: rgb(122, 172, 248);
    font-size: 13px;

}

/* .ant-table-tbody > tr.ant-table-row .highlight:hover{
    background-color:rgb(189, 230, 255);
} */


/* 
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 0px !important; 
} */