/* @media only screen and (max-width:575px) {

    .send-notification {
        width: 99% !important;
    }

    .send-notification>.ant-card-body {
        padding: 0px;
    }
}

@media only screen and (min-width:575px) {

    .send-notification {
        width: 70% !important;
    }
}

.send-notification {
    display: flex;
}

.notificationFormCard{
    width: 80% !important;
} */

.send-notification{
    /* margin: 50px; */
    margin-top: 50px;
    display: flex;
    justify-content: center;
    width: 90% !important;
}