@import '~antd/dist/antd.css';
h4 {
    margin-bottom: 16px;
}

.requirement-container {
    margin: 20px;
    overflow-x: visible;
    height: 85vh;
    text-align: -webkit-center;
    overflow-y: auto;
    margin-bottom: 50px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.requirement-container::-webkit-scrollbar {
    display: none;
}

.confirm-btn {
    float: right;
}

.FormItem {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media only screen and (min-width:551px) {
    .RequirmentCollapse {
        max-width: 880px;
    }

    .requirement-container {
        height: 85vh;
    }
}

@media only screen and(max-width:551px) {
    .requirement-container {
        height: 85vh;
    }  
}

@media screen and (max-width: 700px) {
    .ant-table-thead > tr >th {
        /* color: #7d7272; */
        background: #3071b9 !important;
        font-size: 12px;
        padding: 16px;
    }

    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.3s !important; 
        font-size: 12px;
    }
}
.requirement-container .submit-button .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 100%;
    margin-bottom: 10px;
}

.file-upload-button {
    padding: 10px;
    background-color: white;
    border: 0;
}

@media only screen and (min-width:551px) {
    .file-upload-button {
        max-width: 880px;
    }
}

.submit{
    background-color: #265090;
    color: white;
}

.cancel{
    background-color: #265090;
    color: white;
}

.submit:focus{
    background-color: #265090;
    color: white;
}

.cancel:focus{
    background-color: #265090;
    color: white;
}


