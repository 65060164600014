.Dashboard {
    margin: 20px;
}

.ant-tabs {
    padding-bottom: 10px;
}

.Dashboard::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
    /* or add it to the track */
}

/* Add a thumb */
.Dashboard::-webkit-scrollbar-thumb {
    background: #000;
}

.ant-tabs-content-holder {
    text-align: -webkit-center;
}

.ant-list-items {
    max-width: 980px;
    text-align: left;
}

.backToTop {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4;
    background-color: '#1088e9';
    color: '#fff';
    text-align: 'center';
    font-size: 14;
}

@media only screen and (min-width:551px) {
    .ListDetails>p {
        width: 50%;
    }

    .right {
        float: right;
    }

    .Dashboard .ant-tabs-content-holder {
        overflow-y: scroll;
        max-height: 90vh;
    }

    .Dashboard .ant-tabs {
        height: 95vh;
    }

}

@media only screen and (max-width:551px) {
    .ant-collapse-content>.ant-collapse-content-box {
        padding: 5px;
    }

    .Dashboard {
        height: 87vh;
    }

    .ant-list-item-action {
        display: flex;
        flex-direction: column;
    }

    .ant-list-item-action>li {
        padding: 2px 0px
    }

    .Dashboard .ant-tabs-content-holder {
        overflow-y: scroll;
        max-height: 88vh;
    }

    .Dashboard .ant-tabs {
        max-height: 85vh;
    }

    .LottieAnim{
        margin-top:35%;
    }
}

.ant-card {
    margin: 10px;
    border-radius: 1px solid black;
    border-radius: 10px;
}

.success {
    background-color: rgb(225, 255, 225);
}

.pending {
    background-color: rgb(255, 228, 220);
}

.items {

    display: flex;
    flex-wrap: wrap;
}

.ant-collapse-content-box {
    font-size: 12px;
}

.ListItems {
    max-width: 980px;
    text-align: left;
}

.ant-divider-vertical {
    border-left: 1px solid rgb(0 0 0 / 25%);
}

.site-custom-tab-bar {
    z-index: 1;
    background: #fff;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Dashboard .ant-tabs-content-holder::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Dashboard .ant-tabs-content-holder {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}