@media only screen and (max-width:575px) {
    .UserRegistration {
        width: fit-content;
    }

    .AdminRegistration {
        width: 99% !important;
    }

    .AdminRegistration>.ant-card-body {
        padding: 0px;
    }
}

@media only screen and (min-width:575px) {
    .RegistrationForm .ant-form-item-control-input {
        width: 100%;
    }

    .UserRegistration {
        height: 92vh;
    }

    .AdminRegistration {
        width: 70%;
    }
}

.UserRegistration .ant-input:placeholder-shown {
    font-size: 0.9rem;
}