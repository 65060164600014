.EditProfile{
    height:92vh;
}

.EditLayout{
    margin:4%;
    padding:2%;
}

.ant-form-item-label {
    text-align: right;
}

.submit{
    background-color: #265090;
    color: white;
}

.submit:focus{
    background-color: #265090;
    color: white;
}
