@media only screen and (min-width:551px) {
    .ant-menu-dark.ant-menu-horizontal {
        display: none;
    }

    .ant-menu-dark.ant-menu-inline,
    .ant-menu-dark.ant-menu-vertical,
    .ant-menu-dark.ant-menu-vertical-left,
    .ant-menu-dark.ant-menu-vertical-right {
        border-right: 0;
        height: 100vh;
    }

    .ant-menu-inline-collapsed:not(.ant-layout-sider-children > ul) {
        min-width: 50px !important;
        max-width: 50px !important;
    }
}

@media only screen and (max-width:550px) {
    .ant-menu-inline-collapsed:not(.ant-layout-sider-children > ul) {
        display: none
    }

    .ant-menu-dark.ant-menu-horizontal {
        height: 55px;
        display: flex;
        align-content: center;
        justify-content: space-evenly;
    }

    .ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
    .ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
        height: 55px;
    }

    .ant-menu-item .ant-menu-item-icon {
        margin-right: 0px;
    }

}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
    font-size: 1.4rem;
    font-weight: bold;
    vertical-align: -webkit-baseline-middle;
}

.ant-menu-dark>.ant-menu-item:hover {
    background-color: #FF9600 !important;
}



.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #FF9600 !important;
}

.ant-menu-item-selected {
    background-color: #FF9600 !important;
}

.ant-menu.ant-menu-dark {
    background: #4e8ae4 !important;
}

.Mobile_Menu {
    position: fixed;
    bottom: 0;
    width: 100%;
}