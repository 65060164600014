@import '~antd/dist/antd.css';

.category-container {
  margin: 50px;
  overflow-x: hidden;
  height: 92vh;
}

.ant-table-thead>tr>th {
  /* background: #b32d2b; */
  background: #4e8ae4;
  /* font-size: 1.1rem; */
  color: whitesmoke;
  text-align: center;
  font-weight: bold;

}

.ant-table-filter-column-title {
  font-size: 13px;
}

.ant-table-thead>tr>.ant-table-cell-fix-left-last {
  background-color: #df7d1b !important;
  font-size: 13px;
  font-weight: bold;
}

.ant-table-thead>tr>.ant-table-cell-fix-right-first {
  background-color: rgb(82, 143, 236);
  font-size: 13px;
  font-weight: bold;

}

.ant-table-cell-fix-left-last .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fdbc7a;
  font-size: 13px;
}

.ant-table-cell-fix-right-first .ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(122, 172, 248);
}

.ant-table-filter-trigger-container:hover {
  background: #4e8ae4;
  font-size: 13px;
}

.ant-table-filter-trigger.active {
  color: #b32d2b;
}

.ant-table-filter-trigger {
  color: #333333;

}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
}


.ant-table.ant-table-middle .ant-table-tbody>tr>td {
  font-weight: 600;
}


.btn-continue {
  border-radius: 4px;
  border: none;
  color: #333333;
  text-align: center;
  font-size: 14px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  background-color: #265090;
  color: white;

}

.btn-continue span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  margin-bottom: 0%;
  color: #ffffff;
  background-color: #265090;


}

.btn-continue span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
  background-color: #265090;

}

.btn-continue:hover span {
  padding-right: 25px;
  color: #ffffff;
  background-color: #265090;

}

.btn-continue:hover {
  padding-right: 25px;
  background-color: #265090;

}

.btn-continue:hover span:after {
  opacity: 1;
  right: 0;
  background-color: #265090;

}

.btn-continue:focus {
  background-color: #265090;

}


@media (max-width: 571px) {
  .category-container {
    margin: 10px;
  }
}

@media (min-width: 571px) {
  .category-container {
    margin: 10px;
    height: 98vh;
  }
}

.ant-table-filter-trigger.active {
  background-color: aliceblue;
}