.App {
  text-align: center;
  width: 100%;
  max-height: 100vh;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.App::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.App {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media only screen and (min-width:551px) {
  .App_class {
    flex-direction: row-reverse;
  }
}


/* Make direction as column to make the menu bar to appear at the bottom. */
@media only screen and (max-width:550px) {
  .App {
    max-height: 92vh;
  }

  .App_class {
    flex-direction: column;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

*:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: #FFF !important;
  ;
}

::backdrop {
  z-index: 0;
  background-color: white !important;
}