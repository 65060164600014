@media only screen and (max-width:575px) {
    .UserRegistration {
        width: fit-content;
    }

    .userFormCard {
        margin: 10px;
        padding: 10px;
    }

    .userFormCard>.ant-card-body {
        padding: 0px;
    }
}

@media only screen and (min-width:575px) {
    .RegistrationForm .ant-form-item-control-input {
        width: 60%;
    }

    .UserRegistration {
        height: 92vh;
    }
}

.UserRegistration {
    margin: 20px;
}

.UserRegistration .ant-input:placeholder-shown {
    font-size: 0.56rem;
}