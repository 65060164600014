.category-button-ghost-wrapper {
    display: flex;
    justify-content: center;
}

.category-button-ghost-style {
    margin-left: 10px;
}

@media only screen and (max-width:750px) {
    .category-button-ghost-wrapper {
        display: block !important;
    }

    .category-button-ghost-style {
        margin-left: 0px !important;
        margin-bottom: 10px !important;
    }
}